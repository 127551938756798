import React, { useCallback, useEffect, useState, useRef, RefObject } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import ModalGroup from "../../Shared/Components/ModalGroup"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import RoomHeader from "../Components/RoomHeader"
import RoomPost from "../RoomPost/RoomPost"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { setRoomData, setRoomOwnerData } from "../../Shared/SharedSlices/RoomSlice"
import { RootState } from "../../../App/store"
import { getRoomRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { JoinStatus, PostType, RoomType } from "../../../App/enums"
import { getRoomPostsRequest } from "../RoomPost/RoomPostRequests"
import { setPostStatus, setRoomPostsData } from "../RoomPost/RoomPostSlice"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setIsMember, setPendingType } from "../RoomSlices/MemberInRoomStatusSlice"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import RoomTextArea from "../Components/RoomTextArea"
import RoomInformation from "./RoomInformation"
import { hasPostDropdown } from "../RoomPost/hasPostDropdown"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import RoomMembers from "../Components/RoomMembers"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import MediaLibrary from "./MediaLibrary"


function OrganizationRoomScreen() {
    const translation = useSelector(selectTranslations)
    const { roomId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadingPost, setLoadingPost] = useState(false)
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const dispatch = useDispatch()
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const allow_requests = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.allow_requests!
    const invite_others = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.invite_others!
    const share_documents = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.share_documents!
    const only_admins_can_upload = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.only_admins_can_upload!
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const canSeePosts = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER || isRoomOwner : true
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const postStatus = useSelector((state: RootState) => state.RoomPostsSlice.postStatusSlice.postsStatus)
    const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        switch (searchParams.get('tab')) {
            case 'posts':
                setTabIndex(0)
                break
            case 'members':
                setTabIndex(1)
                break
            case 'media':
                setTabIndex(2)
                break
            default:
                setTabIndex(0)
        }
    }, [searchParams.get('tab')])

    useEffect(() => {
        if (tabIndex !== undefined) {
            searchParams.set('tab', tabIndex === 0 ? 'posts' : tabIndex === 1 ? 'members' : 'media')
            setSearchParams(searchParams)
        }
    }, [tabIndex])


    useEffect(() => {
        let isMounted = true
        getRoomRequest(parseInt(roomId!))
            .then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setRoomData(response.data))
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    navigate("/not-found")
                }
            })
    }, [
        dispatch,
        roomId,
        roomDataState.name,
        roomDataState.photo,
        roomDataState.type,
        allow_requests,
        only_admins_can_upload,
        invite_others,
        share_documents,
        roomDataState.description,
        navigate,
    ])

    const getUserStatus = useCallback(() => {
        return HTTPService.get(`/user-status-in-room/${roomId}/?org=${organizationDataState.id}`, headers)
    }, [organizationDataState.id, roomId])

    useEffect(() => {
        if (roomDataState.id && roomDataState.id === parseInt(roomId!)) {
            getUserStatus().then(response => {
                if (response.status === 200) {
                    dispatch(setIsMember(response.data.org_status.status))
                    dispatch(setPendingType({ type: response.data.org_status.type, notification_id: response.data.org_status.notification_id }))
                }
            })
        }
    }, [dispatch, getUserStatus, roomDataState.id, roomId])

    useEffect(() => {
        dispatch(setRoomOwnerData(roomDataState?.organization?.id === organizationDataState.id))
    }, [dispatch, organizationDataState.id, roomDataState?.organization?.id, roomId])

    useEffect(() => {
        dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
    }, [dispatch])

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller
        if (postStatus) {
            getRoomPostsRequest(parseInt(roomId!), 1)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setRoomPostsData([...posts, ...response.data.results]))
                        setHasNextPage(Boolean(response.data.next))
                        setLoadingPost(false)
                        dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: false }))
                    }
                })
                .catch(error => {
                    if (signal.aborted) return
                    console.log(error)
                })

            return () => {
                setPageNum(1)
                controller.abort()
            }
        }
    }, [dispatch, roomId, postStatus])

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current

            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasNextPage && !postStatus && !loadingPost) {
                const controller = new AbortController()
                const { signal } = controller
                setLoadingPost(true)

                getRoomPostsRequest(parseInt(roomId!), pageNum + 1)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomPostsData([...posts, ...response.data.results]))
                            setHasNextPage(Boolean(response.data.next))
                            setLoadingPost(false)
                            setPageNum(pageNum + 1)
                        }
                    })
                    .catch(error => {
                        if (signal.aborted) return
                        console.log(error)
                    })
                return () => controller.abort()
            }
        }
    }

    return (
        <BackgroundWrapper>
            <Header roomHeader={true} />
            <div className="room-screen">
                <div className="room-layout">
                    <RoomInformation isMemberInRoom={isMemberInRoom} hasPrivelegedPermission={isRoomOwner} />
                    <div className="room-layout__right-panel">
                        <div
                            className={`room-layout__right-panel-header ${(isMemberInRoom === JoinStatus.NON_MEMBER || JoinStatus.PENDING) && "room-layout__right-panel-header--join-button"
                                }`}
                        >
                            <RoomHeader
                                roomType={capitalizeFirstLetter(roomDataState?.type! === RoomType.Private ? translation.private : translation.public)}
                            />
                        </div>

                        <Tabs className="tabs-container" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab>Austausch</Tab>
                                <Tab>{roomDataState?.total_members} {roomDataState?.total_members === 1 ? translation.member : translation.members}</Tab>
                                <Tab>Mediathek</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="room-layout__right-panel-content" ref={scrollContainer} onScroll={onScroll}>
                                    {roomDataState.type && !canSeePosts ? (
                                        <div className="room-layout__no-access">{translation.cantSeeRoomContent}</div>
                                    ) : posts!.length > 0 ? (
                                        posts?.map((post, index) => {
                                            return (
                                                <RoomPost
                                                    key={post.id}
                                                    showCommentIcon={true}
                                                    postDropdown={hasPostDropdown(post, role, organizationDataState.id!, userDataState.id, isRoomOwner)}
                                                    postDropdownStyle={
                                                        index === posts.length - 1
                                                            ? "room-post__right-panel-content room-post__right-panel-content--no-height"
                                                            : "room-post__right-panel-content room-post__right-panel-content--height-5"
                                                    }
                                                    noCursor={post.content_object === null ? true : false}
                                                    post={post}
                                                    postType={"posts"}
                                                    showFullPost={false}
                                                />
                                            )
                                        })
                                    ) : (
                                        <div className="room-layout__no-post">{translation.nopostsYet}</div>
                                    )}

                                    {loadingPost && <LoadingAnimation type="post" />}
                                </div>

                                {roomDataState.is_active && (
                                    <RoomTextArea
                                        hasPrivelegedPermission={isRoomOwner}
                                        isMemberInRoom={isMemberInRoom}
                                        share_documents={share_documents}
                                        type={PostType.post}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                <div className="room-layout__right-panel-content room-layout__right-panel-content--member-panel">
                                    <RoomMembers />
                                </div>
                            </TabPanel>
                            <TabPanel className={'media-library__tabs-panel'}>
                                <MediaLibrary />
                            </TabPanel>
                        </Tabs>

                    </div>
                </div>
            </div>
            <Footer roomfooter={true} />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default OrganizationRoomScreen
