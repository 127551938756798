import React, { useEffect, useState } from 'react';
import Checkbox from '../../Shared/Primitive/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setModalData } from '../../Shared/SharedSlices/ModalSlice';
import Button from '../../Shared/Primitive/Button';
import { selectTranslations } from '../../../App/i18n/i18nSlice';
import { RootState } from '../../../App/store';
import HTTPService from '../../../App/HTTPService';
import { headers } from '../../../App/Axios';
import { MediaLibraryStats, MediaLibraryItem } from '../../../Types/MediaLibrary';
import { MediaLibraryStatsGetRequest } from '../RoomServiceRequests/MediaLibraryRequests';
import FileBrowser from '../MediaLibrary/FileBrowser';
import FileItemPreview from '../MediaLibrary/FileItemPreview';
import SearchInput from '../../Shared/Primitive/SearchInput';
import { setMediaLibraryData } from '../../Shared/SharedSlices/MediaLibrarySlice';
import { TooltipIcon, Upload } from '../../Shared/Components/SvgIcons';
import SessionService from '../../../App/SessionService';
import { RoleType, StorageKey } from '../../../App/enums';
import { CircularProgress, CircularProgressLabel, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Tooltip } from '@chakra-ui/react';
import TutorialBubble from '../../Shared/Components/TutorialBubble';

const MediaLibrary: React.FC = () => {
    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const [isPremiumOrg, setIsPremiumOrg] = useState(roomDataState.organization?.subscription_type != null)
    const [mediaLibraryStats, setMediaLibraryStats] = useState<MediaLibraryStats>()
    const dispatch = useDispatch()
    const [isOrgAdmin, setIsOrgAdmin] = React.useState(false)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const orgId = SessionService.getItem(StorageKey.organizationId)
    const translation = useSelector(selectTranslations)

    useEffect(() => {
        if (roomDataState.id === undefined) { return }

        const requestParams: [number, number?] = [roomDataState.id];
        if (role === RoleType.ORGANIZATION && orgId !== undefined) {
            requestParams.push(Number(orgId!));
        }

        MediaLibraryStatsGetRequest(...requestParams).then(response => {
            if (response.status === 200) {
                setMediaLibraryStats(response.data)
            }
        })
    }, [mediaLibraryState.items])

    useEffect(() => {
        if (Number(roomDataState.organization?.id)) {
            HTTPService.get(`/role-in-org/${roomDataState.organization?.id}/`, headers).then(response => {
                if (response.status === 200) {
                    setIsOrgAdmin(response.data.is_admin)
                }
            })
        }
    }, [roomDataState.organization?.id])

    const onUploadButtonClick = () => {
        if (roomDataState?.room_permissions[0]?.only_admins_can_upload && isOrgAdmin) {
            dispatch(setModalData({ open: true, onlyAdminsCanUploadToLibrary: true }))
        } else {
            dispatch(setModalData({ open: true, roomUploadModal: true }))
        }
    }

    return (
        <div className="media-library__container">
            <TutorialBubble text={translation.tutorial_org_media_library} modifier=" speechbubble__room-media-library" visible={roomDataState.organization?.id === organizationDataState.id && organizationDataState.tutorial_progress === 5} hasNext={true} routeOnNext={`/organization-dashboard/${organizationDataState.id}`} />

            <div className='media-library-header'>
                <div className='media-library-header__actions'>
                    <div className='media-library-header__upload-button-mobile' onClick={onUploadButtonClick}>
                        <Upload />
                    </div>
                    <div className='media-library-header__upload-button-desktop' onClick={onUploadButtonClick}>
                        <Button
                            className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                        >
                            {translation.uploadFile}
                        </Button>
                    </div>
                    <div className='media-library-header__search-input'>
                        <SearchInput
                            onRemoveIconClick={() => dispatch(setMediaLibraryData({ ...mediaLibraryState, filter: { ...mediaLibraryState.filter, query: '' } }))}
                            searchvalue={mediaLibraryState.filter.query}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setMediaLibraryData({ ...mediaLibraryState, filter: { ...mediaLibraryState.filter, query: event.target.value } }))
                            }}
                            isGlobalSearch={false}
                            isChatSearch={false}
                        />
                    </div>
                    <div className='media-library-header__checkboxes'>
                        <div className="media-library-header__checkbox-container">
                            <Checkbox color="dark-blue"
                                checked={mediaLibraryState.filter.documents}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setMediaLibraryData({ ...mediaLibraryState, filter: { ...mediaLibraryState.filter, documents: event.target.checked } }))}
                            />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">
                                Dokumente
                            </div>
                        </div>

                        <div className="media-library-header__checkbox-container">
                            <Checkbox color="dark-blue"
                                checked={mediaLibraryState.filter.images}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setMediaLibraryData({ ...mediaLibraryState, filter: { ...mediaLibraryState.filter, images: event.target.checked } }))}
                            />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">
                                Bilder
                            </div>
                        </div>

                    </div>
                    <div className='media-library-header__file-counter'>
                        {isPremiumOrg ?
                            <>
                                {mediaLibraryStats?.total_items} {mediaLibraryStats?.total_items === 1 ? 'Datei' : 'Dateien'} | {mediaLibraryStats?.total_size ? (mediaLibraryStats.total_size / (1024 * 1024) > 1024 ? (mediaLibraryStats.total_size / (1024 * 1024 * 1024)).toFixed(2) + ' GB' : (mediaLibraryStats.total_size / (1024 * 1024)).toFixed(2) + ' MB') : '0 MB'} | {mediaLibraryStats?.total_size ? ((mediaLibraryStats.total_size / (5 * 1024 * 1024 * 1024)) * 100).toFixed(2) + '%' : '0%'} belegter Speicher
                            </>
                            :
                            <>
                                <Tooltip backgroundColor='#fff' color={'black'} borderRadius={7} border={'1px'} borderColor={'#60A49F'} boxShadow={'lg'} fontSize={12} fontWeight={400} fontFamily={'Inter'} label={translation.upgrade_media_library_storage_with_premium} aria-label='Tooltip'>
                                    {mediaLibraryStats?.total_items} {mediaLibraryStats?.total_items === 1 ? 'Datei' : 'Dateien'} | {mediaLibraryStats?.total_size ? (mediaLibraryStats.total_size / (1024 * 1024) > 1024 ? (mediaLibraryStats.total_size / (1024 * 1024 * 1024)).toFixed(2) + ' GB' : (mediaLibraryStats.total_size / (1024 * 1024)).toFixed(2) + ' MB') : '0 MB'} | {mediaLibraryStats?.total_size ? ((mediaLibraryStats.total_size / (100 * 1024 * 1024)) * 100).toFixed(2) + '%' : '0%'} belegter Speicher
                                </Tooltip>
                            </>}
                    </div>

                </div>
            </div>
            <div className='media-library__content'>
                <FileBrowser isOrgAdmin={isOrgAdmin} />
                {mediaLibraryState.selectedItem && mediaLibraryState.selectedItem.file_name && <FileItemPreview isOrgAdmin={isOrgAdmin} />}
            </div>
        </div>
    );
};

export default MediaLibrary;