import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import ModalGroup from "../../Shared/Components/ModalGroup"
import { BackIcon } from "../../Shared/Components/SvgIcons"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import RoomSinglePostExpanded from "../RoomPost/RoomSinglePostExpanded"
import RoomHeader from "../Components/RoomHeader"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { setRoomData, setRoomOwnerData } from "../../Shared/SharedSlices/RoomSlice"
import { RootState } from "../../../App/store"
import { getRoomRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { JoinStatus, PostType, RoomType } from "../../../App/enums"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setIsMember, setPendingType } from "../RoomSlices/MemberInRoomStatusSlice"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import RoomTextArea from "../Components/RoomTextArea"
import RoomInformation from "./RoomInformation"
import { onBackToRoomNavigation } from "../RoomPost/onNavigation"
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"
import RoomMembers from "../Components/RoomMembers"
import MediaLibrary from "./MediaLibrary"

function OrganizationRoomScreenPostSelected() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const translation = useSelector(selectTranslations)
    const { roomId, id } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const allow_requests = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.allow_requests!
    const invite_others = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.invite_others!
    const share_documents = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.share_documents!
    const only_admins_can_upload = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.only_admins_can_upload!
    const canSeePosts = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER || isRoomOwner : true
    const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);

    useEffect(() => {

        switch (searchParams.get('tab')) {
            case 'posts':
                setTabIndex(0)
                break
            case 'members':
                setTabIndex(1)
                break
            case 'media':
                setTabIndex(2)
                break
            default:
                setTabIndex(0)
        }
    }, [searchParams.get('tab')])

    useEffect(() => {
        if (tabIndex !== undefined) {
            searchParams.set('tab', tabIndex === 0 ? 'posts' : tabIndex === 1 ? 'members' : 'media')
            setSearchParams(searchParams)
        }
    }, [tabIndex])


    useEffect(() => {
        let isMounted = true
        getRoomRequest(parseInt(roomId!))
            .then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setRoomData(response.data))
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [
        dispatch,
        roomId,
        roomDataState?.name,
        roomDataState?.photo,
        roomDataState?.type,
        allow_requests,
        only_admins_can_upload,
        invite_others,
        share_documents,
        roomDataState?.description,
    ])

    const getUserStatus = useCallback(() => {
        return HTTPService.get(`/user-status-in-room/${roomId}/?org=${organizationDataState.id}`, headers)
    }, [organizationDataState.id, roomId])

    useEffect(() => {
        getUserStatus().then(response => {
            if (response.status === 200) {
                dispatch(setIsMember(response.data.org_status.status))
                dispatch(setPendingType({ type: response.data.org_status.type, notification_id: response.data.org_status.notification_id }))
            }
        })
    }, [dispatch, getUserStatus])

    useEffect(() => {
        dispatch(setRoomOwnerData(roomDataState?.organization?.id === organizationDataState.id))
    }, [dispatch, organizationDataState.id, roomDataState?.organization?.id, roomId])

    return (
        <BackgroundWrapper>
            <Header roomHeader={true} />
            <div className="room-screen">
                <div className="room-layout">
                    <RoomInformation isMemberInRoom={isMemberInRoom} hasPrivelegedPermission={isRoomOwner} />
                    <div className="room-layout__right-panel">
                        <div
                            className={`room-layout__right-panel-header ${(isMemberInRoom === JoinStatus.NON_MEMBER || JoinStatus.PENDING) && "room-layout__right-panel-header--join-button"
                                }`}
                        >
                            <RoomHeader
                                roomType={capitalizeFirstLetter(roomDataState?.type! === RoomType.Private ? translation.private : translation.public)}
                            />
                        </div>

                        <Tabs className="tabs-container" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab>Austausch</Tab>
                                <Tab>{roomDataState?.total_members} {roomDataState?.total_members === 1 ? translation.member : translation.members}</Tab>
                                <Tab>Mediathek</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="room-layout__right-panel-content">
                                    {roomDataState.type && !canSeePosts ? (
                                        <div className="room-layout__no-access">{translation.cantSeeRoomContent}</div>
                                    ) : (
                                        <>
                                            <div className="room-layout__back-icon-container" onClick={() => onBackToRoomNavigation(role, roomId!, id!, navigate)}>
                                                <div className="room-layout__back-icon">
                                                    <BackIcon />
                                                </div>
                                                <div className="room-layout__text">{translation.allPosts}</div>
                                            </div>
                                            <RoomSinglePostExpanded />
                                        </>
                                    )}
                                </div>
                                {roomDataState.is_active && (
                                    <RoomTextArea
                                        hasPrivelegedPermission={isRoomOwner}
                                        isMemberInRoom={isMemberInRoom}
                                        share_documents={share_documents}
                                        type={PostType.comment}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                <div className="room-layout__right-panel-content room-layout__right-panel-content--member-panel">
                                    <RoomMembers />
                                </div>
                            </TabPanel>
                            <TabPanel className={'media-library__tabs-panel'}>
                                <MediaLibrary />
                            </TabPanel>
                        </Tabs>

                    </div>
                </div>
            </div>
            <Footer roomfooter={true} />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default OrganizationRoomScreenPostSelected
